/**
    * @description      : 
    * @author           : 44757
    * @group            : 
    * @created          : 22/01/2023 - 16:42:47
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 22/01/2023
    * - Author          : 44757
    * - Modification    : 
**/
const typography = {
	fontFamily: 'RobotoRegular !important',

	body1: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 400, // or 'bold'
	},
	h1: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
		fontSize: '1.755rem',
		lineHeight: '1.5',
	},
	h2: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
		fontSize: '1.47rem',
		lineHeight: '1.5',
	},
	h3: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
		fontSize: '1.32rem',
		lineHeight: '1.5',
	},
	h4: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
		fontSize: '1.2rem',
		lineHeight: '1.5',
	},
	h5: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 700,
		fontSize: '1rem',
		lineHeight: '1.5',
	},
	h6: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
		fontSize: '0.8rem',
		lineHeight: '1.5',
	},

	small: {
		fontFamily: 'RobotoRegular !important',
		fontWeight: 500,
		fontSize: '1.07rem',
		lineHeight: '1.5',
	},
	button: {
		fontFamily: 'RobotoRegular !important',
		textTransform: 'none',
		fontWeight: '500 !important',
	},
	subtitle1: {
		fontFamily: 'RobotoRegular !important',
		fontSize: '1.1rem',
		fontWeight: '300',
	},
	subtitle2: {
		fontFamily: 'RobotoRegular !important',
		fontSize: '0.875rem',
		fontWeight: '300',
	},
	p: {

		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
	},

	input: {

		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
	},

	textarea: {

		fontFamily: 'RobotoRegular !important',
		fontWeight: 400,
	}
};

export default typography;
