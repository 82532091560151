/**
 * @description      :
 * @author           : 44757
 * @group            :
 * @created          : 06/01/2023 - 12:16:25
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 06/01/2023
 * - Author          : 44757
 * - Modification    :
 **/
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import { configureStore } from './redux/Store'

import './data'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import Spinner from './views/spinner/Spinner'


const root = document.getElementById('root');

ReactDOM.createRoot(root).render(
	<Provider store={configureStore()}>
		<Suspense fallback={<Spinner />}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Suspense>
	</Provider>
);




// If you want to enable client cache, register instead.
serviceWorker.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
