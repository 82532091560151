/**
    * @description      : 
    * @author           : 44757
    * @group            : 
    * @created          : 04/11/2021 - 09:17:17
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/11/2021
    * - Author          : 44757
    * - Modification    : 
**/
import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import categoriesReducer from './categories/CategoriesReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  chatReducer,
categoriesReducer,
  notesReducer,
	emailReducer,
});

export default RootReducers;
