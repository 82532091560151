/**
    * @description      : 
    * @author           : 44757
    * @group            : 
    * @created          : 04/11/2021 - 10:18:50
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/11/2021
    * - Author          : 44757
    * - Modification    : 
**/
import {  SELECTED_CATEGORY, SEARCH_CATEGORY, MSG_SUBMIT, FETCH_CATEGORIES_SUCCESS } from '../constants';

const INIT_STATE = {
  chatContent: 1,
  chatSearch: '',
  chats: [],
};

const CategoriesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        chats: action.chats,
      };

    case SELECTED_CATEGORY:
      return {
        ...state,
        chatContent: action.id,
      };
    case MSG_SUBMIT:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat.id === action.id
            ? { ...chat, ...chat.chatHistory[0][1].to.push(action.chatMsg) }
            : chat,
        ),
      };
    case SEARCH_CATEGORY:
      return {
        ...state,
        chatSearch: action.searchTerm,
      };
    default:
      return state;
  }
};

export default CategoriesReducer;
