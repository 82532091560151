/**
    * @description      : 
    * @author           : 44757
    * @group            : 
    * @created          : 04/11/2021 - 10:26:26
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/11/2021
    * - Author          : 44757
    * - Modification    : 
**/
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';

// CHAT CONSTANTS
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';
export const SEARCH_CATEGORY = 'SEARCH_CATEGORY';

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';
