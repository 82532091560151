/**
 * @description      :
 * @author           : 44757
 * @group            :
 * @created          : 04/11/2021 - 12:09:46
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/11/2021
 * - Author          : 44757
 * - Modification    :
 **/
import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import $ from 'jquery'

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'))
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'))
/* ***End Layouts**** */

const Error = lazy(() => import('../views/authentication/Error'))
const Home = lazy(() => import('../views/authentication/Home'))
const LoginMobile = lazy(() => import('../views/authentication/LoginMobile'))
const RegisterMobile = lazy(() => import('../views/authentication/RegisterMobile'))

const Login = lazy(() => import('../views/authentication/Login'))
const Register = lazy(() => import('../views/authentication/Register'))
const AddVehicle = lazy(() => import('../views/authentication/AddVehicle'))
const ResetPassword = lazy(() => import('../views/authentication/ResetPassword'))

/* ****Pages***** */
const Dashboard1 = lazy(() => import('../views/dashboards/Dashboard1'))
const MarketPlace = lazy(() => import('../views/dashboards/MarketPlace'))
const BookingsPage = lazy(() => import('../views/dashboards/BookingsPage'))
const MenuPage = lazy(() => import('../views/dashboards/MenuPage'))
const Dashboard1Mobile = lazy(() => import('../views/dashboards/Dashboard1Mobile'))
const Dashboard2 = lazy(() => import('../views/dashboards/Dashboard2'))
const Dashboard3 = lazy(() => import('../views/dashboards/Dashboard3'))

/* ****Apps***** */
const Chats = lazy(() => import('../views/apps/chats/Chats'))
const Notes = lazy(() => import('../views/apps/notes/Notes'))
const Email = lazy(() => import('../views/apps/email/Email'))
const Shop = lazy(() => import('../views/apps/services/index'))
const Business = lazy(() => import('../views/apps/business/index'))
const Favorites = lazy(() => import('../views/apps/favorites/index'))
const Vehicles = lazy(() => import('../views/apps/vehicles/index'))
const QuillEditor = lazy(() => import('../views/quill-editor/QuillEditor'))
// const Treeview = lazy(() => import('../views/treeview/Treeview'))
const Pricing = lazy(() => import('../views/pricing/Pricing'))
const CustomTimeline = lazy(() => import('../views/timeline/CustomTimeline'))
const CustomTypography = lazy(() => import('../views/typography/CustomTypography'))
const Calendar = lazy(() => import('../views/apps/calendar/ACalendar'))
const CustomerEdit = lazy(() => import('../views/apps/customers/CustomerEdit'))
const ProfileEdit = lazy(() => import('../views/apps/profile/ProfileEdit'))
const CustomerLists = lazy(() => import('../views/apps/customers/CustomerLists'))
/* ****Tables***** */
const BasicTable = lazy(() => import('../views/tables/BasicTable'))
const PaginationTable = lazy(() => import('../views/tables/PaginationTable'))
const EnhancedTable = lazy(() => import('../views/tables/EnhancedTable'))
const CollapsibleTable = lazy(() => import('../views/tables/CollapsibleTable'))
const FixedHeaderTable = lazy(() => import('../views/tables/FixedHeaderTable'))

// form elements
const ExAutoComplete = lazy(() => import('../views/form-elements/ExAutoComplete'))
const ExButton = lazy(() => import('../views/form-elements/ExButton'))
const ExCheckbox = lazy(() => import('../views/form-elements/ExCheckbox'))
const ExDateTime = lazy(() => import('../views/form-elements/ExDateTime'))
const ExRadio = lazy(() => import('../views/form-elements/ExRadio'))
const ExSlider = lazy(() => import('../views/form-elements/ExSlider'))
const ExSwitch = lazy(() => import('../views/form-elements/ExSwitch'))
const FormWizard = lazy(() => import('../views/form-layouts/FormWizard'))
// form layouts
const FormLayouts = lazy(() => import('../views/form-layouts/FormLayouts'))
const FormCustom = lazy(() => import('../views/form-layouts/FormCustom'))

// widgets
const WidgetFeed = lazy(() => import('../views/widgets/widget-feed/WidgetFeed'))
const WidgetApps = lazy(() => import('../views/widgets/widget-apps/WidgetApps'))

// userprofile
const UserProfile = lazy(() => import('../views/user-profile/UserProfile'))
const ShopDetail = lazy(() => import('../views/apps/shop-detail/ShopDetail'))
const VehicleDetail = lazy(() => import('../views/apps/vehicle-detail/VehicleDetail'))

// chart
const LineChart = lazy(() => import('../views/charts/LineChart'))
const GredientChart = lazy(() => import('../views/charts/GredientChart'))
const DoughnutChart = lazy(() => import('../views/charts/DoughnutChart'))
const AreaChart = lazy(() => import('../views/charts/AreaChart'))
const ColumnChart = lazy(() => import('../views/charts/ColumnChart'))
const CandlestickChart = lazy(() => import('../views/charts/CandlestickChart'))
const RadialbarChart = lazy(() => import('../views/charts/RadialbarChart'))

// icons
const ReactIcons = lazy(() => import('../views/icons/ReactIcons'))

// Alert
const ExAlert = lazy(() => import('../views/alert/ExAlert'))

const sessionToken = localStorage.getItem('sessionToken')

/* ****Routes***** */

const slug = str => {
	const str1 = str.replace(/^\s+|\s+$/g, '') // trim
	let str2 = str1.toLowerCase()

	// remove accents, swap ñ for n, etc
	const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
	const to = 'aaaaaeeeeeiiiiooooouuuunc------'
	for (let i = 0, l = from.length;i < l;i++) {
		str2 = str2.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
	}

	return str2
		.replace(/[^a-z0-9 -]/g, '')
		.replace(/\s+/g, '-')
		.replace(/-+/g, '-')
}

const ajaxCall = options => {
	return $.ajax(options).fail()
}

let childrenCotent

if (sessionToken) {

	childrenCotent = [
		{ path: '/', element: <Navigate to='/dashboards/dashboard1' /> },
		{ path: '/addvehicle', element: <Navigate to='/auth/addvehicle' /> },
		{ path: '/dashboards/dashboard1_mobile', exact: true, element: <Dashboard1Mobile /> },
		{ path: '/dashboards/dashboard1', exact: true, element: <Dashboard1 /> },
		{ path: '/bookings', exact: true, element: <BookingsPage /> },
		{ path: '/menu', exact: true, element: <MenuPage /> },
		{ path: '/marketplace', exact: true, element: <MarketPlace /> },
		{ path: '/dashboards/dashboard2', exact: true, element: <Dashboard2 /> },
		{ path: '/dashboards/dashboard3', exact: true, element: <Dashboard3 /> },
		{ path: '/vehicles', exact: true, element: <Vehicles /> },
		{ path: '/addvehicle', exact: true, element: <AddVehicle /> },
		{ path: '/favorites', exact: true, element: <Favorites /> },
		{ path: '/vehicles/vehicle-detail', exact: true, element: <VehicleDetail /> },
		{ path: '/business-directory', exact: true, element: <Business /> },
		{ path: '/customers/lists', exact: true, element: <CustomerLists /> },
		{ path: '/chats', element: <Chats /> },
		{ path: '/notes', element: <Notes /> },
		{ path: '/email', element: <Email /> },
		{ path: '/calendar', element: <Calendar /> },
		{ path: '/profile', element: <ProfileEdit /> },
		{ path: '/customers/edit', element: <CustomerEdit /> },
		{ path: '/tables/basic-table', element: <BasicTable /> },
		{ path: '/tables/pagination-table', element: <PaginationTable /> },
		{ path: '/tables/enhanced-table', element: <EnhancedTable /> },
		{ path: '/tables/collapsible-table', element: <CollapsibleTable /> },
		{ path: '/tables/fixed-header-table', element: <FixedHeaderTable /> },
		{ path: '/form-layouts/form-layouts', element: <FormLayouts /> },
		{ path: '/form-elements/autocomplete', element: <ExAutoComplete /> },
		{ path: '/form-elements/button', element: <ExButton /> },
		{ path: '/form-elements/checkbox', element: <ExCheckbox /> },
		{ path: '/form-elements/date-time', element: <ExDateTime /> },
		{ path: '/form-elements/radio', element: <ExRadio /> },
		{ path: '/form-elements/slider', element: <ExSlider /> },
		{ path: '/form-elements/switch', element: <ExSwitch /> },
		{ path: '/form-layouts/form-wizard', element: <FormWizard /> },
		{ path: '/widgets/widget-feed', element: <WidgetFeed /> },
		{ path: '/widgets/widget-apps', element: <WidgetApps /> },
		{ path: '/user-profile', element: <UserProfile /> },
		{ path: '/business-directory/shop-detail', element: <ShopDetail /> },
		{ path: '/charts/line-chart', element: <LineChart /> },
		{ path: '/charts/gredient-chart', element: <GredientChart /> },
		{ path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
		{ path: '/charts/area-chart', element: <AreaChart /> },
		{ path: '/charts/column-chart', element: <ColumnChart /> },
		{ path: '/charts/candlestick-chart', element: <CandlestickChart /> },
		{ path: '/charts/radialbar-chart', element: <RadialbarChart /> },
		{ path: '/react-icons', element: <ReactIcons /> },
		{ path: '/form-layouts/form-custom', element: <FormCustom /> },
		{ path: '/quill-editor', element: <QuillEditor /> },
		// { path: '/treeview', element: <Treeview /> },
		{ path: '/pricing', element: <Pricing /> },
		{ path: '/timeline', element: <CustomTimeline /> },
		{ path: '/typography', element: <CustomTypography /> },
		{ path: '/alert', element: <ExAlert /> },
		{ path: '*', element: <Navigate to='/auth/404' /> },
	]


} else {
	childrenCotent = [
		{ path: '/', element: <Navigate to='/auth/login' /> },
		{ path: '*', element: <Navigate to='/auth/login' /> },
	]
}
try {
	const jqXhr = ajaxCall({
		url: `${process.env.REACT_APP_API_URL}crm/categories/`,
		data: {
			operation_get: 'load',
			lang_get: 'en',
			category_get: 0,
		},
		dataType: 'json',
		type: 'GET',
		async: false,
		cache: true,
	})

	jqXhr.done(response => {
		$.each(response, (i, item) => {
			const childrenContent = {
				path: `/business-directory/${slug(item.name)}`,
				element: (
					<Shop
						breadcrumb={item.name}
						category={item.name}
						refferer={1}
						BCrumb={[{ to: '/', title: 'Home' }, { title: item.name }]}
					/>
				),
			}

			if (sessionToken) {
				childrenCotent.push(childrenContent)
			}
		})

		$.each(response, (i, item) => {
			const childrenContent1 = {
				path: `/business-directory/${slug(item.name)}/shop-detail`,
				element: <ShopDetail />,
			}

			if (sessionToken) {
				childrenCotent.push(childrenContent1)
			}
		})
	})
} catch (err) {
	console.log(err)
}

const Router = [
	{
		path: '/',
		element: <FullLayout />,
		children: childrenCotent,
	},
	{
		path: 'auth',
		element: <BlankLayout />,
		children: [
			{ path: '404', element: <Error /> },
			{ path: 'home', element: <Home /> },
			{ path: 'loginmobile', element: <LoginMobile /> },
			{ path: 'login', element: <Login /> },
			{ path: 'AddVehicle', element: <AddVehicle /> },
			{ path: 'register', element: <Register /> },
			{ path: 'registermobile', element: <RegisterMobile /> },
			{ path: 'reset-password', element: <ResetPassword /> },
			{ path: '*', element: <Navigate to='/auth/404' /> },
		],
	},
]

export default Router
