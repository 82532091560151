import React, { useCallback, Suspense, memo, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import axios from 'axios';
import { CssBaseline, ThemeProvider } from '@mui/material'
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings'
import Router from './routes/Router'
// import * as serviceWorker from './serviceWorker';
import Spinner from './views/spinner/Spinner'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './App.css'
// import SpeachToText from './components/common/SpeachToText'


const App = () => {
	const sessionToken = localStorage.getItem('sessionToken')
	const operation = 'crmload'

	const loadSession = useCallback(async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}session/`, {
				params: {
					operation,
					sessionToken,
				},
			});

			if (Array.isArray(response.data)) {
				response.data.forEach(item => {
					if (item && typeof item === 'object') {
						Object.keys(item).forEach(key => {
							localStorage.setItem(key, item[key] ?? '');
						});
					}
				});
			}

			document.querySelector('meta[name="theme-color"]').setAttribute('content', '#102888');
			localStorage.setItem('assistantVoice', 'Microsoft Dalia Online (Natural) - Spanish (Mexico)');
		} catch (error) {
			console.error(error);
		}
	}, [operation, sessionToken]);


	const showCityAndCountry = useCallback(async () => {
		try {
			navigator.geolocation.watchPosition(async position => {
				const { latitude, longitude } = position.coords;
				localStorage.setItem('latitude', latitude);
				localStorage.setItem('longitude', longitude);

				await loadSession();

				/*	const ipDataResponse = await axios.get('https://api.ipdata.co', {
						params: { 'api-key': '49f01718b611a163d70657b281d18aa369daa3fee0bf3d6fe8ec13a3' },
						responseType: 'json',
					});
	
					Object.keys(ipDataResponse.data).forEach(key => {
						if (typeof ipDataResponse.data[key] === 'object') {
							Object.keys(ipDataResponse.data[key]).forEach(subKey => {
								localStorage.setItem(`${key}_${subKey}`, ipDataResponse.data[key][subKey]);
							});
						} else {
							localStorage.setItem(key, ipDataResponse.data[key]);
						}
					});*/

				const ipifyResponse = await axios.get('https://api.ipify.org', {
					params: { format: 'json' },
				});

				localStorage.setItem('ipAddress', ipifyResponse.data.ip);
			});
		} catch (error) {
			console.error(error);
		}
	}, []);


	const routing = useRoutes(Router)
	// const [firstLoad, setFirstLoad] = React.useState()
	const theme = ThemeSettings()
	// const customizer = useSelector(state => state.CustomizerReducer)
	const [isNotch_, setIsNotch] = React.useState(true)

	const hasNotch = useCallback(() => {
		let proceed = false;
		const div = document.createElement('div');
		if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
			div.style.paddingBottom = 'env(safe-area-inset-bottom)';
			proceed = true;
		} else if (CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
			div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
			proceed = true;
		}
		if (proceed) {
			document.body.appendChild(div);
			const calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10);
			document.body.removeChild(div);
			if (calculatedPadding > 0) {
				return true;
			}
		}
		return false;
	}, []);

	useEffect(() => {
		loadSession();

		if ('serviceWorker' in navigator && 'PushManager' in window) {
			navigator.serviceWorker.register('/sw.js')
				.then((swReg) => {
					console.log('Service Worker is registered', swReg);

					swReg.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: '<Your Public VAPID Key Here>'
					})
						.then((subscription) => {
							console.log('User is subscribed:', subscription);
						})
						.catch((err) => {
							console.log('Failed to subscribe the user: ', err);
						});
				})
				.catch((error) => {
					console.error('Service Worker Error', error);
				});
		}
	}, [loadSession]);

	if (navigator.geolocation) {
		navigator.geolocation.watchPosition((position) => {
			localStorage.setItem('latitude_', position.coords.latitude)
			localStorage.setItem('speed_', position.coords.speed)
			localStorage.setItem('longitude_', position.coords.longitude)

		});
	}
	localStorage.removeItem('firstLoad');

	React.useEffect(() => {
		if (isNotch_) {
			localStorage.setItem('isNotch_', true)
		} else {
			localStorage.setItem('isNotch_', false)
		}

	}, [isNotch_])

	React.useEffect(() => {
		setIsNotch(hasNotch())
		document.querySelector('meta[name="theme-color"]').setAttribute('content', '#FFF');
		// navigator.mediaDevices.getUserMedia({ audio: true });
		showCityAndCountry()
		if (window.localStorage) {
			if (!localStorage.getItem('firstLoad') && localStorage.getItem('sessionToken')) {
				localStorage.removeItem('mode');
				localStorage.removeItem('selected_mode')
			}

		}

	}, [])

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<Spinner />}>
				<CssBaseline />
				{routing}
				{/* <SpeachToText />*/}
			</Suspense>
		</ThemeProvider >
	)
}



export default memo(App)
